import React, { useRef, useEffect, useState, useMemo, useCallback } from 'react';
import { length, along, bearing as turfBearing, point as turfPoint, lineChunk, bearingToAngle } from '@turf/turf';
import styled from 'styled-components';
import classNames from 'classnames';
import moment from 'moment';
import DeckGL from '@deck.gl/react';
import mapboxGl from 'mapbox-gl';
import { Map } from 'react-map-gl';
import { PathLayer, IconLayer } from '@deck.gl/layers';
import { PathStyleExtension } from '@deck.gl/extensions';
import Contacts from './Contacts';

import 'mapbox-gl/dist/mapbox-gl.css'

import CarIcon1 from './icons/car_1.svg';
import CarIcon2 from './icons/car_2.svg';
import CarIcon3 from './icons/car_3.svg';

import ArrowIcon from './icons/arrow.svg';
import CalendarIcon from './icons/calendar.svg';
import RoadIcon from './icons/road.svg';

import { ReactComponent as ArrowDownIcon } from './icons/arrow_down.svg';

import centerLineData from './data/center_line.json';
import lineData from './data/lane_line.json'
import { ROUTES } from './data/routes';

// eslint-disable-next-line import/no-webpack-loader-syntax
mapboxGl.workerClass = require("worker-loader!mapbox-gl/dist/mapbox-gl-csp-worker").default;

const copy = object => JSON.parse(JSON.stringify(object));

const loadImage = async (map, id, source) => {
  return new Promise((resolve, reject) => {
    const img = new Image();
    img.onload = () => resolve(map.addImage(id, img));
    img.onerror = e => reject(e);
    img.src = source;
  });
}

const MAPBOX_ACCESS_TOKEN = 'pk.eyJ1IjoidG9wb2wiLCJhIjoiY2lndjM4eDNxMDA0M3Zma3JiOGRmcGNyOSJ9.tPBrXFyMAspRCTjyVKmx8A';
const MAP_STYLE = 'mapbox://styles/mapbox/dark-v11';

const INITIAL_VIEW_STATE = {
  longitude: -115.14349654281853,
  latitude: 36.1644238975622,
  zoom: 16.10490836123168,
  minZoom: 3,
  maxZoom: 25,
  pitch: 37.000000000000036,
  bearing: -31.122300079547188,
};

const ROUTE_TIME = { start: new Date().setHours(8, 0, 0, 0), duration: 12 * 60 * 60 * 1000 /* 12 hours */ };
const TIME_MODIFIER = ROUTE_TIME.duration / (60 * 1000) /* 30s */  

const generateRoute = (route) => {
  return {
    type: 'FeatureCollection',
    features: route.map((routeChunk) => {
      const centerLine = copy(centerLineData.features.find(f => f.properties.id === routeChunk.id));
      const maxTime = Math.max(...ROUTES.map(route => route[route.length -1].time_end));
      const timeModifier = ROUTE_TIME.duration / maxTime;

      centerLine.properties.time_start = ROUTE_TIME.start + (routeChunk.time_start * timeModifier);
      centerLine.properties.time_end = ROUTE_TIME.start + (routeChunk.time_end * timeModifier);

      if (routeChunk.lane_line_id) {
        for (const id of routeChunk.lane_line_id) {
          const lane = lineData.features.find(f => f.properties.id === id);
          lane.properties.time_start = ROUTE_TIME.start + (routeChunk.time_start * timeModifier);
          lane.properties.time_end = ROUTE_TIME.start + (routeChunk.time_end * timeModifier);
        }
      }
   
      return centerLine;
    }),
  }
}

const generateCarPoint = (route, icon, angle) => {
  return  {
    'type': 'Feature',
    'properties': {
      'angle': angle,
      'route': route,
      'icon': icon,
      'hidden': true,
    },
    'geometry': {
      'type': 'Point',
      'coordinates': route.features[0].geometry.coordinates[0]
    }
  };
}

function App() {
  const mapRef = useRef(null);
  const deckRef = useRef(null);
  const [progress, setProgress] = useState(0);
  const [date, setDate] = useState(moment(ROUTE_TIME.start).format('DD MMM YYYY'));
  const [time, setTime] = useState('');
  const [isRunning, setRunning] = useState(false);
  const [mapped, setMapped] = useState('0');
  const [showLegend, setShowLegend] = useState(false);
  const startRef = useRef(null);
  const drawRef = useRef(null);
  const requestRef = useRef(0);
  const startTimeRef = useRef(0);
  const timeRunningRef = useRef(0);
  const isRunningRef = useRef(isRunning);

  const [data, setData] = useState({
    cars: [
      generateCarPoint(generateRoute(ROUTES[0]), CarIcon1, 0),
      generateCarPoint(generateRoute(ROUTES[1]), CarIcon2, 0),
      generateCarPoint(generateRoute(ROUTES[2]), CarIcon3, 0),
    ],
    lines: [],
  });

  const dataRef = useRef(data);

  useEffect(() => { dataRef.current = data; }, [data]);

  useEffect(() => {
    isRunningRef.current = isRunning;
  }, [isRunning]);

  const draw = (timestamp) => {
    drawRef.current?.(timestamp);
  }

  const onProgressClick = (e) => {
    const p = e.nativeEvent.offsetX / e.target.clientWidth * 100;
    timeRunningRef.current = ROUTE_TIME.duration / 100 * p;
    draw(ROUTE_TIME.start + timeRunningRef.current);
    startRef.current?.();
  }

  const onHourClick = (hour) => {
    const timestamp = new Date().setHours(hour, 0, 0, 0);
    timeRunningRef.current = timestamp - ROUTE_TIME.start;
    draw(timestamp);
    startRef.current?.();
  }

  const stateNow = () => {
    const now = new Date().getTime();
    timeRunningRef.current = now - ROUTE_TIME.start;
    draw(now);
    startRef.current?.();
  }

  const layers = useMemo(() => (
    [
      new PathLayer({
        id: 'lines',
        data: data.lines,
        getColor: d => [255, 255, 255],
        getPath: d => d.geometry.coordinates,
        getWidth: d => 1,
        widthMinPixels: 1,
        widthMaxPixels: 2,
        widthScale: 1,
        jointRounded: true,
        pickable: true,
        parameters: { depthMask: false },
        getDashArray: d => d.properties.type === 3 ? [10, 10] : [0, 0],
        extensions: [new PathStyleExtension({ highPrecisionDash: true })]
      }),
      new IconLayer({
        id: 'cars',
        data: data.cars,
        getIcon: d => ({ url: d.properties.icon, width: 80, height: 80 }),
        sizeScale: 15,
        sizeMinPixels: 5,
        sizeMaxPixels: 100,
        getAngle: d => 360 - (d.properties.angle + 180),
        getPosition: d => d.geometry.coordinates,
        getSize: d => 4,
        getColor: d => [0, 255, 0, d.properties.hidden ? 0 : 255],
        billboard: false,
      }),
    ]
  ), [data]);

  const onMapboxLoad = useCallback(async () => {

    window.onkeydown = e => {
      if (e.code === 'Space') {
        const isHidden = map.getLayer('center-lines').isHidden();
        map.setLayoutProperty('center-lines', 'visibility', isHidden ? 'visible' : 'none');
        map.setLayoutProperty('center-lines-direction', 'visibility', isHidden ? 'visible' : 'none');
      }
    }

    const map = mapRef.current.getMap();
    await loadImage(map, 'arrow-icon', ArrowIcon);

    map.addSource('center-lines', {
      'type': 'geojson',
      'data': centerLineData,
    });

    map.addLayer({
      'id': 'center-lines',
      'source': 'center-lines',
      'type': 'line',
      'paint': {
        'line-width': 1,
        'line-color': '#007BFE',
        'line-opacity': 1,
        'line-dasharray': [8, 8],
      },
      'layout': {
        'line-join': 'round',
        'line-cap': 'round',
      },
    });

    map.addLayer({
      'id': 'center-lines-direction',
      'source': 'center-lines',
      'type': 'symbol',
      'paint': {},
      'layout': {
        'icon-size': 0.5,
        'symbol-placement': 'line',
        'symbol-spacing': 100,
        'icon-image': 'arrow-icon',
        'icon-rotation-alignment': 'map',
        'icon-allow-overlap': true,
        'icon-ignore-placement': true,
      }
    });

    map.addLayer(
      {
        id: 'mapbox-buildings',
        type: 'fill-extrusion',
        source: 'composite',
        'source-layer': 'building',
        filter: ['==', 'extrude', 'true'],
        minzoom: 15,
        paint: {
          'fill-extrusion-color': '#aaa',
          'fill-extrusion-height': ['interpolate', ['linear'], ['zoom'], 15, 0, 15.05, ['get', 'height']],
          'fill-extrusion-base': ['interpolate', ['linear'], ['zoom'], 15, 0, 15.05, ['get', 'min_height']],
          'fill-extrusion-opacity': 0.8,
        },
      },
    );

    const draw = (timestamp) => {
      const progress = (timestamp - ROUTE_TIME.start) / ROUTE_TIME.duration * 100;
      setDate(moment(timestamp).format('DD MMM YYYY'));
      setTime(moment(timestamp).format('hh:mm'));
      setProgress(progress > 100 ? 100 : progress < 0 ? 0 : progress);

      const distances = dataRef.current.cars.map(car => {
        const staticLines = car.properties.route.features.filter(f => f.properties.time_end <= timestamp);
        const lengths = staticLines.map(line => length(line, { units: 'miles' }));
        return lengths.reduce((acc, val) => acc + val, 0);
      });

      const cars = dataRef.current.cars.map(car => {
        const route = car.properties.route;
        const chunk = route.features.find(f => timestamp >= f.properties.time_start && timestamp < f.properties.time_end);
        if (chunk) {
          const currentPathChunk = copy(chunk);
          const pathChunkDistance = length(currentPathChunk, { units: 'miles' });
          const pathChunkTimeDuration = currentPathChunk.properties.time_end - currentPathChunk.properties.time_start;
          const pathChunkTime = timestamp - currentPathChunk.properties.time_start;
          const pathChunkTimePercent = pathChunkTime / pathChunkTimeDuration * 100;
          const pathChunkPosition = pathChunkDistance / 100 * pathChunkTimePercent;

          const newCoords = along(currentPathChunk, pathChunkPosition, { units: 'miles' }).geometry.coordinates;
          const bearingTimePercent = pathChunkTimePercent <= 99 ? pathChunkTimePercent + 1 : 100;
          const bearingChunkPosition = pathChunkDistance / 100 * bearingTimePercent;
          const bearingCoords = along(currentPathChunk, bearingChunkPosition, { units: 'miles' }).geometry.coordinates;
          const bearing = turfBearing(turfPoint(newCoords),turfPoint(bearingCoords));

          car.geometry.coordinates = newCoords;
          car.properties.angle = bearingToAngle(bearing);
          car.properties.hidden = false;
          distances.push(pathChunkPosition);
        } else {
          const firstChunk = route.features[0];
          const lastChunk = [...route.features].reverse().find(f => timestamp >= f.properties.time_end);
          const timeBefore = timestamp < firstChunk.properties.time_start;
          const currentPathChunk = copy(timeBefore ? firstChunk : lastChunk);
          const firstCoords = currentPathChunk.geometry.coordinates[0];
          const lastCoords = currentPathChunk.geometry.coordinates[currentPathChunk.geometry.coordinates.length - 1];
          const bearing = turfBearing(turfPoint(firstCoords),turfPoint(lastCoords));
          
          car.geometry.coordinates = timeBefore ? firstCoords : lastCoords;
          car.properties.angle = bearingToAngle(bearing);
          car.properties.hidden = timeBefore;
        }

        return car;
      });
     
      setMapped(distances.reduce((acc, val) => acc + val, 0).toFixed(1));

      // update green lines
      const linesTimestamp = timestamp - (2 * 60 * 1000); // 2 min
      const staticLines = copy(lineData.features.filter(f => f.properties.time_end < linesTimestamp));
      const animatedLines = copy(lineData.features.filter(f => linesTimestamp >= f.properties.time_start && linesTimestamp < f.properties.time_end));
      const animatedChunks = animatedLines.map(animatedLine => {
        const animatedLinkDistance = length(animatedLine, { units: 'miles' });
        const animatedLinkTimeDuration = animatedLine.properties.time_end - animatedLine.properties.time_start;
        const animatedLinkTime = linesTimestamp - animatedLine.properties.time_start;
        const animatedLinkTimePercent = animatedLinkTime / animatedLinkTimeDuration * 100;
        const animatedLinkPosition = animatedLinkDistance / 100 * animatedLinkTimePercent;
        if (animatedLinkPosition) {
          const animatedLinkChunk = lineChunk(animatedLine, animatedLinkPosition, { units: 'miles' }).features[0];
          animatedLinkChunk.properties = animatedLine.properties;
          return animatedLinkChunk;
        }
        return null;
      });

      const lines = [...staticLines, ...animatedChunks].filter(Boolean);
      
      setData({ cars, lines });
    }

    const animate = () => {
      const now = performance.now();
      timeRunningRef.current += (now - startTimeRef.current) * TIME_MODIFIER;
      const timestamp = ROUTE_TIME.start + timeRunningRef.current;

      startTimeRef.current = now;

      if (timestamp > ROUTE_TIME.start + ROUTE_TIME.duration) {
        timeRunningRef.current = 0;
        setRunning(false);
        return;
      }

      draw(timestamp);
      // request another frame
      requestRef.current = requestAnimationFrame(animate);
    }

    const startAnimation = () => {
      cancelAnimationFrame(requestRef.current);
      startTimeRef.current = performance.now();
      setRunning(true);
      animate();
    }

    startAnimation();
    startRef.current = startAnimation;
    drawRef.current = draw;
  }, []);

  return (
    <StyledMapWrapper onContextMenu={e => e.preventDefault()}>
      <DeckGL
        ref={deckRef}
        initialViewState={INITIAL_VIEW_STATE}
        controller={true}
        layers={layers}
        pickingRadius={5}
      >
        <Map
          ref={mapRef}
          mapboxAccessToken={MAPBOX_ACCESS_TOKEN}
          mapStyle={MAP_STYLE}
          maxTileCacheSize={100000}
          refreshExpiredTiles
          antialias
          onLoad={() => {
            onMapboxLoad();
            window.map = mapRef.current.getMap();
            window.reactMap = mapRef.current;
            window.deck = deckRef.current;
          }}
        />
      </DeckGL>
      <StyledProgressBar>
        <div className="top-info">
          <div className="date">
            <img src={CalendarIcon} alt="calendar" />
            <span>{date}</span>
          </div>
          <div className="mapped">
            <img src={RoadIcon} alt="road" />
            <span><span className="count">{mapped}</span> miles</span>
          </div>
        </div>
        <div className="time-info">
          <div className="time"><span>{time}</span></div>
          <div className="progress-wrapper">
            <div className="hours">
              {[...Array(13).keys()].map(i => i + 8).map(i => (
                i % 4 === 0 && (
                  <div
                    key={i}
                    className="hour"
                    style={{ left: (100 / 12 * (i - 8)) + '%' }}
                    onClick={() => onHourClick(i)}
                  >
                    {i > 12 ? i - 12 : i}{i < 12 ? 'am' : 'pm'}
                  </div>
                )
              ))}
            </div>
            <div className="progress-inner" onClick={onProgressClick}>
              <div className="progress">
                <div className="progress-ticks">
                  <div className="progress-tick"></div>
                  <div className="progress-tick"></div>
                </div>
                <div className="progress-arrow" style={{ width: progress + '%' }}>
                  <img src={ArrowIcon} alt="progress-arrow" />
                </div>
              </div>
            </div>
          </div>
          <div className="live" onClick={stateNow}>
            <div className="dot"></div>
            <div className="label">live</div>
          </div>
        </div>
      </StyledProgressBar>
      <Legend>
        <div className="vehicles">
          <div className="title">Vehicles</div>
          <div className="group">
            <img src={CarIcon1} alt="vehicle-1" />
            <img src={CarIcon2} alt="vehicle-2" />
            <img src={CarIcon3} alt="vehicle-3" />
          </div>
        </div>
        <div className={classNames('content', { open: showLegend })}>
          <div className="content-inner">
            <div className="separator"></div>
            <div className="legend">
              <div className="legend-item">
                <div className="line"></div>
                <div className="description">Road Edge</div>
              </div>
              <div className="legend-item">
                <div className="line"></div>
                <div className="description">White Single Solid Line</div>
              </div>
              <div className="legend-item">
                <div className="line"></div>
                <div className="description">White Single Dashed Line</div>
              </div>
              <div className="legend-item">
                <div className="line"></div>
                <div className="description">Lane Graph</div>
              </div>
            </div>
            <div className="hint">Press SPACE to show/hide Lane Graph</div>
          </div>
        </div>
        <div className={classNames('expand', { open: showLegend })}>
          <div
            className="icon"
            onClick={() => setShowLegend(prev => !prev)}
          >
              <ArrowDownIcon/>
          </div>
        </div>
      </Legend>
      <Contacts />
    </StyledMapWrapper>
  );
}

const StyledMapWrapper = styled.div`
  position: relative;
  height: 100vh;
  width: 100vw;
  overflow: hidden;
  border-radius: inherit;
  display: flex;

  .map {
    width: 100%;
    height: 100%;
    flex-grow: 1;
  }
`;

const Legend = styled.div`
  position: absolute;
  top: 20px;
  right: 20px;
  background: linear-gradient(112deg, rgba(14, 14, 14, 0.9) 0%, rgba(14, 14, 14, 0.27) 100%);
  box-shadow: 0px 1px 24px rgba(0, 0, 0, 0.1);
  backdrop-filter: blur(5px);
  border-radius: 10px;
  border: 1px solid #5e5c64;
  color: #fff;
  font-family: 'Montserrat';
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  padding: 20px;
  width: max-content;
  max-width: calc(100vw - 40px);

  @media screen and (max-width: 720px) {
    padding: 15px;
    right: 50%;
    transform: translateX(50%);
  }

  .vehicles {

    .title {
      text-align: center;
      margin-bottom: 15px;
    }

    .group {
      display: flex;
      justify-content:space-around;

      img {
        width: 40px;
        height: 40px;
        
        &:not(:last-child) {
          margin-right: 10px;
        }
      }
    }
  }

  .separator {
    border: 1px solid #5e5c64;
    margin: 20px 0;
  }

  .legend-item {
    display: flex;
    align-items: center;
    
    &:not(:last-child) {
      margin-bottom: 10px;
    }
  }

  .legend-item .line {
    width: 64px;
    height: 2px;
    margin-right: 10px;
  }

  .hint {
    margin-top: 20px;
    color: #5e5c64;
    font-size: 12px;
    text-align: center;

    @media screen and (max-width: 720px) {
      display: none;
    }

  }

  .legend-item:nth-child(1) .line {
    background-color: #fff;
  }

  .legend-item:nth-child(2) .line {
    background-color: #fff;
  }

  .legend-item:nth-child(3) .line {
    background: repeating-linear-gradient(to right,white 0,white 8px,transparent 8px,transparent 14px);
  }

  .legend-item:nth-child(4) .line {
    background: repeating-linear-gradient(to right,#5165a8 0,#5165a8 8px,transparent 8px,transparent 14px);
  }

  .content {
    display: grid;
    grid-template-rows: 0fr;
    transition: grid-template-rows 0.2s ease-in-out;

    @media screen and (min-width: 720px) {
      grid-template-rows: 1fr;
    }

    &.open {
      grid-template-rows: 1fr;
    }

    &-inner {
      overflow: hidden;
    }

  }

  .expand {
    display: none;
    align-items: center;
    justify-content: center;
    margin-top: 15px;

    @media screen and (max-width: 720px) {
      display: flex;
    }

    &.open .icon svg {
      transform: rotate(180deg);
    }

    .icon {
      display: flex;
      justify-content: center;
      align-items: center;
      padding: 5px;
      border-radius: 4px;
      cursor: pointer;

      svg {
        transition: transform 0.2s linear;
      }
      
      &:hover {
        background-color: #ffffff11;
      }
    }
  }

`;

const StyledProgressBar = styled.div`
  background: linear-gradient(112deg, rgba(14, 14, 14, 0.9) 0%, rgba(14, 14, 14, 0.27) 100%);
  box-shadow: 0px 1px 24px rgba(0, 0, 0, 0.1);
  backdrop-filter: blur(5px);
  border-radius: 10px;
  border: 1px solid #5e5c64;
  color: #fff;
  font-family: 'Montserrat';
  position: absolute;
  bottom: 20px;
  left: 0;
  right: 0;
  margin-left: auto;
  margin-right: auto;
  font-size: 18px;
  padding: 30px;
  user-select: none;
  width: max-content;
  max-width: calc(100vw - 100px);

  @media screen and (max-width: 720px) {
    padding: 15px;
  }

  @media screen and (max-width: 1100px) {
    bottom: 30px;
  }

  .top-info {
    display: flex;
    align-items: center;
    font-size: 15px;
    color: #fff;
    position: relative;
    margin-bottom: 20px;

    @media screen and (max-width: 720px) {
      font-size: 12px;
      margin-bottom: 10px;
    }

    &:before, &:after {
      content: '';
      display: block;
      position: absolute;
      background-color: #5e5c64;
    }

    &:after {
      left: 50%;
      top: 0;
      width: 2px;
      height: 100%;
      transform: translateX(-1px);
    }

    &:before {
      left: 0;
      top: calc(100% + 20px);
      width: 100%;
      height: 2px;

      @media screen and (max-width: 720px) {
        top: calc(100% + 10px);
      }
    }

    & > * {
      width: 50%;
    }

    img {
      height: 15px;
      margin-right: 7px;
      margin-right: 10px;
    }

    .date, .mapped {
      display: flex;
      justify-content: center;
      align-items: center;
      white-space: nowrap;
    }

    .mapped .count {
      display: inline-flex;
      justify-content: center;
      width: 25px;
    }

  }

  .time-info {
    position: relative;
    display: flex;
    align-items: center;
    padding: 20px 0 10px 0;

    @media screen and (max-width: 720px) {
      padding: 10px 0 5px 0;
    }

    .time {
      width: 44px;
      display: flex;
      align-items: center;
      margin-right: 10px;
      font-size: 15px;
      transform: translateY(10px);

      @media screen and (max-width: 720px) {
        font-size: 12px;
      }

    }

    .progress-wrapper {
      width: 299px;

      .progress-inner {
        padding: 5px 0;

        .progress {
          width: 100%;
          height: 4px;
          background-color: #66646D;
          border-radius: 100px;
          position: relative;

          &:hover {
            background-color: #9591a2;
          }

          > * {
            pointer-events: none;
          }

          .progress-arrow {
            position: absolute;
            left: 0;
            top: 50%;
            transform: translateY(-50%);
            width: 0%;
            height: 6px;
            background: linear-gradient(89.99deg, #668CFF 0%, #396AFC 99.99%);
            border-radius: 100px;
            user-select: none;
            pointer-events: none;

            img {
              position: absolute;
              left: 100%;
              top: 50%;
              transform: translate(-50%, -50%);
              height: 15px;
            }
          }

          .progress-ticks {
            width: 100%;
            height: 100%;
            position: absolute;
            top: 0;
            lefT: 0;

            .progress-tick {
              position: absolute;
              top: 0;
              width: 4px;
              height: 100%;
              background-color: #353a3d;
              transform: translateX(-50%);

              &:nth-child(1) {
                left: 33.33%;
              }

              &:nth-child(2) {
                left: 66.66%;
              }
            }
            
          }

        }

        &:hover .progress {
          background-color: #9591a2;
        }
      }

      .hours {
        position: relative;
        height: 15px;
        width: calc(100% - 27px);
        margin: 0 0px 8px 13px;

        .hour {
          position: absolute;
          top: 0px;
          font-size: 12px;
          color: #fff;
          transform: translateX(-50%);
          cursor: pointer;

          @media screen and (max-width: 720px) {
            font-size: 10px;
          }

          &:hover {
            color: #85a4ff;
          }
        }

      }

    }

    .live {
      margin-left: 15px;
      display: flex;
      justify-content: center;
      align-items: center;
      padding: 8px 12px;
      gap: 5px;
      background-color: rgba(57, 106, 252, 0.2);
      border-radius: 5px;
      transition: background-color 0.2s linear;
      cursor: pointer;
      transform: translateY(10px);

      @media screen and (max-width: 720px) {
        padding: 5px 7px;
      }

      &:hover {
        background-color: rgba(57, 106, 252, 0.3);
      }

      .dot {
        width: 8px;
        height: 8px;
        background: #396AFC;
        border-radius: 50%;
      }

      .label {
        font-weight: 700;
        font-size: 15px;
        color: #396AFC;
        margin-left: 2px;
        text-transform: uppercase;

        @media screen and (max-width: 720px) {
          font-size: 12px;
        }
      }
    }

  }

`;

export default App;
